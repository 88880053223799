import React from 'react';
import Styles from './image.module.scss';
import Button from '../button/button';
import HighlightH1 from '../highlight-h1/highlight-h1';

export default props => (
    <section style={{ backgroundImage: `url(${props.slice.primary.image.url})` }} className={`${Styles.section} my-5 d-flex flex-column-reverse`}>
        <div className="container pb-5">
            <div className="row pb-5">
                <div className="col-12 pb-5">
                    <Button
                        text={props.slice.primary.link_text.text}
                        link={props.slice.primary.link.text}
                        color={props.slice.primary.color} />
                </div>
            </div>
        </div>
        <div className={`container ${Styles.absBottom}`}>
            <HighlightH1
                row1={props.slice.primary.row_1.text}
                row2={props.slice.primary.row_2.text}
                row3={props.slice.primary.row_3.text}
                color={props.slice.primary.color} />
        </div>
    </section>
);
