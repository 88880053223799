import React from 'react';
import Styles from './image-gallery.module.scss';
import GalleryService from './gallery.service';

const MAX_IMAGE_AMOUNT = 6;

export default props => {
    if (props.slice.items.length > MAX_IMAGE_AMOUNT) {
        console.debug(`No image grid layout is defined for ${props.slice.items.length} images! Falling back to max amount of ${MAX_IMAGE_AMOUNT}.`);
        props.slice.items = props.slice.items.slice(0, MAX_IMAGE_AMOUNT);
    }

    const galleryService = new GalleryService();
    const openGallery = image => console.warn('TODO: Handle image gallery!!!');

    return (
        <section className="py-5">
            <div className="container py-5">
                {/* Normal row/col for small screens */}
                <div className="row d-md-none">
                    {props.slice.items.map((item, index) => (
                        <div key={index} className="col-12">
                            <img src={item.image.url} className="w-100 rounded mb-3"></img>
                        </div>
                    ))}
                </div>
                {/* Grid-Based layouts for larger screens */}
                {/* The specific layout is defined by the amount of images (box-X classes) in CSS */}
                <div className="row d-none d-md-block">
                    <div className={Styles.wrapper}>
                        {props.slice.items.map((item, index) => (
                            <div key={index} className={`b b-${props.slice.items.length} b-${props.slice.items.length}-i-${index + 1}`} style={{ backgroundImage: `url(${item.image.url})` }}
                                onClick={() => openGallery(item.image)}>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
