class GalleryService {
    static instance: GalleryService;

    private _currentImage: string;
    private _imageUrls: string[];
    private _isOpen = false;

    constructor() {
        if (GalleryService.instance) {
            return GalleryService.instance;
        }
        GalleryService.instance = this;
    }

    get currentImage() {
        return this._currentImage;
    }

    get isOpen() {
        return this._isOpen;
    }

    private get currentImageIndex() {
        return this._imageUrls.findIndex(img => img === this._currentImage);
    }

    open(imageUrls: string[], currentImage: string) {
        this._imageUrls = imageUrls;
        this._currentImage = currentImage;
        this._isOpen = true;
        this.updateBodyClass();
    }

    close() {
        this._isOpen = false;
        this.updateBodyClass();
    }

    next() {
        if (this.currentImageIndex < (this._imageUrls.length - 1)) {
            this._currentImage = this._imageUrls[this.currentImageIndex + 1]
        } else {
            this._currentImage = this._imageUrls[0];
        }
    }

    prev() {
        if (this.currentImageIndex > 0) {
            this._currentImage = this._imageUrls[this.currentImageIndex - 1]
        } else {
            this._currentImage = this._imageUrls[this._imageUrls.length - 1];
        }
    }

    private updateBodyClass() {
        const className = 'overflow-hidden';
        const element = document.body;
        if (this._isOpen) {
            element.classList.add(className);
        } else {
            element.classList.remove(className);
        }
    }
}

export default GalleryService;
